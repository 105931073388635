<template>
  <b-card>
    <b-overlay
        :show="state.loading"
        rounded="sm"
    >
      <!-- User Info: Input Fields -->
      <b-form @submit.prevent="sendData">
        <b-row>
          <!-- Field: RoleName -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="نام نقش"
                label-for="roleName"
            >
              <b-form-input
                  id="roleName"
                  v-model="userData.persianName"
              />
            </b-form-group>
          </b-col>

          <!-- Field: RoleValue -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="نام انگلیسی"
                label-for="en-name"
            >
              <b-form-input
                  id="en-name"
                  v-model="userData.englishName"
              />
            </b-form-group>
          </b-col>

          <!-- Field: SelectIcon -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="آیکون نقش"
                label-for="role-icon"
            >
              <v-select
                  v-model="userData.iconName"
                  dir="rtl"
                  :options="icons"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="role-icon"
                  label="value"
              >
                <template #option="{ value }">
                  <feather-icon
                      :icon="value"
                      size="16"
                      class="align-middle mr-50"
                  />
                  <span> {{ value }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: SelectVariant -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="رنگ آیکون"
                label-for="icon-variant"
            >
              <v-select
                  v-model="userData.color"
                  dir="rtl"
                  :options="variants"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="icon-variant"
              >
                <template #option="{ value , label }">
                  <span :class="`text-${value}`">{{ label }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
              cols="12"
              md="4"
          >
            <b-form-group
                label="وضعیت"
                label-for="status"
            >
              <v-select
                  v-model="userData.isActive"
                  dir="rtl"
                  :options="status"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="status"
              >
                <template #option="{ variant , label }">
                  <span :class="`text-${variant}`">{{ label }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

        </b-row>
      </b-form>

      <!-- PERMISSION TABLE -->
      <b-card
          no-body
          class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
                icon="LockIcon"
                size="18"
            />
            <span class="align-middle ml-50">دسترسی ها</span>
          </b-card-title>
        </b-card-header>
        <b-table
            striped
            responsive
            class="mb-0"
            :fields="header"
            :items="items"
        >
          <!--                    <template #head()="data">
                                  <b-form-checkbox v-if="typeof data.field.value==='boolean'"
                                                   @change="checkAll(data.column,data.field.value)" :checked="data.field.value"
                                                   v-model="data.field.value">{{data.label}}
                                  </b-form-checkbox>
                              </template>-->

          <template #cell(module)="{item}">
            {{ roleLabel(item) || item }}
          </template>
          <template #cell()="{item,field}">
            <b-form-checkbox v-if="rules.includes(field.key + '_' + item)" :value="field.key + '_' + item"
                             v-model="userData.privileges"/>
          </template>
        </b-table>
      </b-card>

      <!-- Action Buttons -->
      <b-button
          variant="primary"
          type="submit"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click.prevent="sendData"
      >
        {{ id !== undefined ? 'اصلاح نقش' : 'افزودن نقش' }}
      </b-button>
      <b-button
          variant="outline-secondary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          type="reset"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click.prevent="resetData"
      >
        از نو
      </b-button>
      <b-button
          variant="outline-danger"
          type="back"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click.prevent="goBack"
      >
        بازگشت
      </b-button>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BOverlay,
} from "bootstrap-vue"
import vSelect from "vue-select"
import '@core/scss/vue/libs/vue-select.scss'

const header = [
  {
    label: 'ماژول',
    key: 'module'
  },
  {
    label: 'لیست',
    key: 'LIST',
    value: false,
  },
  {
    label: 'جزییات',
    key: 'DETAILS',
    value: false,
  },
  {
    label: 'ساخت',
    key: 'CREATE',
    value: false,
  },
  {
    label: 'تغییر',
    key: 'UPDATE',
    value: false,
  },
  {
    label: 'حذف',
    key: 'DELETE',
    value: false,
  },
]
const icons = [
  {value: 'ActivityIcon',},
  {value: 'AirplayIcon',},
  {value: 'AnchorIcon',},
  {value: 'ApertureIcon',},
  {value: 'ArchiveIcon',},
  {value: 'AtSignIcon',},
  {value: 'AwardIcon',},
  {value: 'BarChart2Icon',},
  {value: 'BatteryChargingIcon',},
  {value: 'BellIcon',},
  {value: 'BookOpenIcon',},
  {value: 'BriefcaseIcon',},
  {value: 'CalendarIcon',},
  {value: 'CameraIcon',},
  {value: 'CastIcon',},
  {value: 'ClockIcon',},
  {value: 'CodeIcon',},
  {value: 'CoffeeIcon',},
  {value: 'CpuIcon',},
  {value: 'CreditCardIcon',},
  {value: 'DatabaseIcon',},
  {value: 'DivideSquareIcon',},
  {value: 'DivideIcon',},
  {value: 'DollarSignIcon',},
  {value: 'DownloadIcon',},
  {value: 'DribbbleIcon',},
  {value: 'Edit3Icon',},
  {value: 'EditIcon',},
  {value: 'EyeIcon',},
  {value: 'FileTextIcon',},
  {value: 'GlobeIcon',},
  {value: 'HardDriveIcon',},
  {value: 'HomeIcon',},
  {value: 'HeadphonesIcon',},
  {value: 'InstagramIcon',},
  {value: 'LockIcon',},
  {value: 'PhoneCallIcon',},
  {value: 'PieChartIcon',},
  {value: 'PrinterIcon',},
  {value: 'PowerIcon',},
  {value: 'RepeatIcon',},
  {value: 'RssIcon',},
  {value: 'ServerIcon',},
  {value: 'SettingsIcon',},
  {value: 'Share2Icon',},
  {value: 'ShieldIcon',},
  {value: 'ShoppingBagIcon',},
  {value: 'ShoppingCartIcon',},
  {value: 'ToolIcon',},
  {value: 'TrendingUpIcon',},
  {value: 'UserIcon',},
  {value: 'WifiIcon',},
  {value: 'Volume2Icon',},
  {value: 'UnlockIcon',},
  {value: 'ZapIcon',},
]
const variants = [
  {value: 'primary', label: 'آبی پررنگ'},
  {value: 'secondary', label: 'خاکستری'},
  {value: 'success', label: 'سبز'},
  {value: 'warning', label: 'زرد'},
  {value: 'danger', label: 'قرمز'},
  {value: 'info', label: 'آبی روشن'},
]
const status = [
  {variant: 'success', label: 'فعال', value: true},
  {variant: 'danger', label: 'غیر فعال', value: false},
]
const modules = {
  MARKET: 'بازار ها',
  ORDERS: 'سفارش ها',
  TRADES: 'معاملات',
  USERS: 'کاربران سایت',
  ADMINS: 'مدیران',
  ROLES: 'نقش ها',
  DOLLARS: 'مدیریت دلار',
  WALLET: 'کیف پول ها',
  WALLET_ADDRESS: 'آدرس ها',
  WITHDRAW: 'درخواست های برداشت',
  HISTORIES: 'تاریخچه',
  TRANSACTIONS: 'تراکنش ها',
  EXCHANGEINFO: 'اطلاعات بازار',
  FAQ: 'سوالات متداول',
  USER_MANUAL: 'راهنمای استفاده',
  EXCHANGE_INFO: 'اطلاعات سایت',
  NOTIFICATIONS: 'پیام ها',
  ACCOUNT_LEVEL: 'سطوح کاربری',
  CARDS: 'کارت ها',
  CARD_TYPE: 'نوع کارت ها',
  PROFILE_ADMINS: 'پروفایل مدیر',
}

export default {
  name: "addNewRole",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
    BTable,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
    BOverlay,
  },
  props: ['id'],
  data() {
    return {
      userData: {
        persianName: '',
        englishName: '',
        color: '',
        isActive: '',
        iconName: '',
        privileges: [],
      },
      items:[],
      rules:[],
      checked: {},
      header,
      icons,
      variants,
      status,
      modules,
    }
  },

  watch: {
    '$route.params.id'() {
      if (this.id !== undefined)
        this.setData()
      else {
        this.resetData()
      }
    }
  },

  computed: {
    roleAccess() {
      const a = {
        UPDATE: 'اصلاح',
        LIST: 'لیست',
        DELETE: 'حذف',
        CREATE: 'ایجاد',
        DETAILS: 'جزییات',
      }
      return e => a[e];
    },
    roleLabel() {
      return e => modules[e];
    },
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/roles')
    },
    checkAll(a, e) {
      console.log(a, e)
      // for (let key in this.modules)
      for (let value of this.permissionsData) {
        console.log(value)
        if (value[e])
          this.userData.privileges.push(e + '_' + value.module)
      }
    },
    async setData() {
      this.state.loading = true

      const res = await this.$axios(`/roles/${this.id}`)

      this.userData = res.data.baseDTO
    },
    resetData() {
      if (this.id !== undefined)
        this.setData()
      else
        this.userData = {
          persianName: '',
          englishName: '',
          color: 'primary',
          isActive: '',
          iconName: 'UserIcon',
          privileges: [],
        }
      console.log(this.userData)
    },
    async sendData() {
      this.state.loading = true

      let address
      if (this.id !== undefined)
        address = '/roles/' + this.id
      else
        address = '/roles/'

      const res = await this.$axios.post(address, this.userData)

      if (res.data.message.includes('Created')) {
        res.data.baseDTO.id
        this.$router.push('/roles/show-role/' + res.data.baseDTO.id)
      } else if (res.data.message.includes('Updated')) {
        await this.setData()
      }
    }
  },
  async created() {

    this.state.loading = true
    const res = await this.$axios('/roles/privileges')
    // this.privileges = res.data
    this.rules = res.data
    const data = res.data
    let uniqueModules = data.map(e => e.replace(/DELETE_|UPDATE_|CREATE_|DETAILS_|LIST_/, ''))
    uniqueModules = [...new Set(uniqueModules)]
    this.items = uniqueModules

    /*this.privileges = await this.privileges.map(x => {
      return {
        label: this.roleAccess(x.split('_')[0]) + ' ' + this.roleLabel(x.split('_')[1] + (x.split('_')[2] ? ('_' + x.split('_')[2]) : '')),
        value: x
      }
    })
    console.warn('privileges : =>>>> ', this.privileges)*/

  },
  mounted() {
    if (this.id !== undefined)
      this.setData()
    else {
      this.resetData()
    }
  }
}
</script>

<style lang="scss">
.custom-checkbox {
  input {
    cursor: pointer
  }
}
</style>
