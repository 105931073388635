import Vue from 'vue';

const that = Vue.prototype

that.$perivilageData = [
    {
        module: 'USERS',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'ADMINS',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'ROLES',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'DOLLARS',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: false,
        DELETE: false,
    },
    {
        module: 'WALLET',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: false,
    },
    {
        module: 'WITHDRAW',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'HISTORIES',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: false,
        DELETE: false,
    },
    {
        module: 'FAQ',
        LIST: true,
        DETAILS: false,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'USER_MANUAL',
        LIST: true,
        DETAILS: false,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'TRADES',
        LIST: true,
        DETAILS: true,
        CREATE: false,
        UPDATE: true,
        DELETE: false,
    },
    {
        module: 'ORDERS',
        LIST: true,
        DETAILS: false,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
    {
        module: 'MARKET',
        LIST: true,
        DETAILS: true,
        CREATE: true,
        UPDATE: true,
        DELETE: true,
    },
]

that.$accountLevel = []
that.$botLink = 'http://localhost:3333'
that.$urls = [['آوینکس','https://aviinex.xyz/api'],['تیرکس','https://tiirex.website/exchange/api'],['رادفینکس','https://novin.click/api']]

that.$subPerivilage = [
    'UPDATE',
    'LIST',
    'DELETE',
    'CREATE',
    'DETAILS',
]

that.$ethBase = ['BINANCE_COIN_BSC','DOGE_COIN']

that.$decimal = {
    BITCOIN:8,
    BITCOIN_CASH:3,
    DOGE_COIN:0,
    LITE_COIN:3,
    DASH:3,
    ETHEREUM:4,
    CLASSIC_ETHEREUM:2,
    TETHER:2,
    TOMAN:0,
    TRON:1,
    CELO:1,
    MATIC:1,
    RIPPLE:0,
    STELLAR:0,
    ELROND:2,
    BINANCE_COIN_BSC:3,
}

that.$coinLabel = {
    TOMAN: 'تومان',
    BITCOIN: 'بیتکوین',
    BITCOIN_CASH: 'بیتکوین کش',
    ETHEREUM: 'اتریوم',
    // CLASSIC_ETHEREUM: 'اتریوم کلاسیک',
    LITE_COIN: 'لایت کوین',
    BINANCE_COIN_BSC: 'بایننس کوین',
    // EOS: 'ایاس',
    STELLAR: 'استلار',
    RIPPLE: 'ریپل',
    TRON: 'ترون',
    // DASH: 'دش',
    TETHER: 'تتر',
    DOGE_COIN: 'دوج کوین',
    CELO: 'سلو',
    MATIC: 'ماتیک',
    ELROND: 'الروند',
}

that.$coinUnit = {
    TOMAN: 'تومان',
    BITCOIN: 'BTC',
    BITCOIN_CASH: 'BCH',
    ETHEREUM: 'ETH',
    // CLASSIC_ETHEREUM: 'ETC',
    LITE_COIN: 'LTC',
    BINANCE_COIN_BSC: 'BNB',
    // EOS: 'EOS',
    STELLAR: 'XLM',
    RIPPLE: 'XRP',
    TRON: 'TRX',
    // DASH: 'DASH',
    TETHER: 'تتر',
    DOGE_COIN: 'DOGE',
    CELO: 'CELO',
    MATIC: 'MATIC',
    ELROND: 'EGLD',
}

that.$errorHandeling = {
    BAD_REQUEST: 'اطلاعات ارسال شده اشتباه است',
    NOT_FOUND: 'اطلاعات درخواستی یافت نشد',
    FINNOTECH_NOT_COMPLETED: 'کاربر فرآیند فینوتک را تکمیل نکرده است',
    CONFLICT: 'اجازه ندارید',
    FORBIDDEN: 'دسترسی لازم را ندارید',
    CARD_NUMBER_DOESNT_MATCH_SHEBA: 'عدم تطابق شماره کارت و شماره شبا',
    EXCEL_NOT_GENERATED: 'اطلاعاتی جهت خروجی اکسل وجود ندارد',
    INCORRECT_WALLET_PASSWORD: 'رمز کیف پول اشتباه است',
    DUPLICATE_NAME: 'نام کیف پول تکراریست.',
}