import Vue from 'vue'

// axios
import axios from 'axios'

const that = Vue.prototype

if (that.state.token) {
    // console.log('I have token : ', that.state.token)
    axios.defaults.headers.common['Authorization'] = that.state.token
}

// axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.baseURL = 'https://novin.click/api'

if (process.env.NODE_ENV === 'development') {
// axios.defaults.baseURL = 'http://localhost:8098/api'
//     axios.defaults.baseURL = 'https://aviinex.xyz/api'
//     axios.defaults.baseURL = 'https://novin.click/api'
    axios.defaults.baseURL = 'https://novin.click/api'
}

axios.interceptors.response.use(
    (res) => {
        that.state.loading = false

        // console.log('result : ', res)

        return res;
    },
    async (err) => {
        that.state.loading = false

        const res = err.response || err.toJSON()

        // console.error('result : ', res);

        /*console.warn(
            //     'config : ', err.config,
            //     'code : ', err.code,
            'request : ', err.request,
            //     'response : ', err.response,
            //     'isAxios : ', err.isAxiosError,
            //     'json : ', err.toJSON()
        )*/

        if (res.config.responseType === 'blob') {
            const vm = that
            let reader = new FileReader();
            reader.onload = function (e) {
                let test = JSON.parse(e.target.result);
                vm.$error('', vm.$errorHandeling[test.message])
            };
            reader.readAsText(err.response.data);
            return Promise.reject(err);
        }

        if (err.response) if (res.data.message) if (res.data.message.includes('JWT') || res.data.message.includes('Invalid Token')) {
            that.$logout()
            return Promise.reject(err);
        }

        if (err.response ? ['UNAUTHORIZED'].includes(res.data.status) : false) {
            if (that.$errorHandeling[err.response.data.message]) {
                that.$error(that.$errorHandeling[err.response.data.message])
            }
            return Promise.reject(err);
        }

        if (err.response ? ['Validation Error'].includes(res.data.message) : false) {
            return Promise.reject(err);
        }

        if (res.data.message === 'Market Is Inactive') {
            that.$error('بازار غیر فعال است')
            return Promise.reject(err);
        }

        const text = err.response ?
            that.$errorHandeling[err.response.data.message]
            || that.$errorHandeling[err.response.data.status]
            || res.data.errors
            || res.data.error
            : res.message

        that.$swal({
            icon: 'error',
            title: err.request.status,
            text: text,
            confirmButtonText: 'متوجه شدم',
            customClass: {
                confirmButton: 'btn btn-success',
            },
        })

        return Promise.reject(err);
    },);

that.$axios = axios

let func = async (config) => {
    try {
        const res = await axios(config)
        return [res, null]
    } catch (e) {
        return [null, e]
    }
}
const props = {
    get: async (url, config) => {
        try {
            const res = await axios.get(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    post: async (url, data, config = {}) => {
        try {
            const res = await axios.post(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    put: async (url, data, config = {}) => {
        try {
            const res = await axios.put(url, data, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
    delete: async (url, config = {}) => {
        try {
            const res = await axios.delete(url, config)
            return [res, null]
        } catch (e) {
            return [null, e]
        }
    },
}
for (let prop in props) {
    func[prop] = props[prop];
}
that.$http = func;

export default axios
