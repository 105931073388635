import Vue from "vue";
import jmoment from 'moment-jalaali'

let that = Vue.prototype

that.$dateFormat = {
    fa: 'dddd jDD jMMMM jYYYY',
    en: 'YYYY-MM-DDTHH:mm:ss',
    faDate: 'jYYYY/jM/jD',
    faDateTime: 'jYYYY/jM/jD - HH:mm:ss',
    faDTime: 'dddd - HH:mm:ss',
    faTime: 'HH:mm',
    faTimes: 'HH:mm:ss',
    enDate: 'YYYY-MM-DD',
}

jmoment.loadPersian({dialect: 'persian-modern'})

that.$G2J = (e,toType='fa',fromType='en') => {
    return jmoment(e, that.$dateFormat[fromType]).format(that.$dateFormat[toType])
}
that.$J2G = (e,toType='en',fromType='faDate') => {
    return jmoment(e, that.$dateFormat[fromType]).format(that.$dateFormat[toType])
}

Vue.prototype.$jmoment = jmoment
export default jmoment